.about {
	&__container {
		display: grid;
		grid-template-columns: 2.2fr 1fr;
		grid-template-rows: auto;
		height: 100%;
		width: 100%;
		align-items: center;
		
	}
	
	&__heal {
		//align-self: center;
		display: grid;
		width: 100%;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 1.5em;
		grid-row-gap: 1em;
	}
	
	&__label {
		color: var(--primary-color);
		font-size: 0.7rem;
		font-family: var(--ffm-main);
		font-weight: 400;
		margin-top: 2em;
		width: 6.5rem;
		text-align: center;
	}
	
	&__description {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 3em;
	}
	
	&__title, &__subtitle, &__text {
		color: var(--primary-color);
	}
	
	&__title {
		font-size: 1.8rem;
		font-family: var(--ffm-main);
		font-weight: 400;
		margin-bottom: 1em;
	}
	
	&__subtitle {
		font-family: var(--ffm-roboto);
		font-weight: 200;
		font-size: 1.1rem;
		margin-bottom: 2em;
	}
}

:root {
	--card-width: 11rem;
	--card-height: 17rem;
	--card-height-half: 8.5rem;
	
}

.shuffle {
	width: 100%;
	display: grid;
	grid-template-rows: 5rem 1fr;
	grid-row-gap: 3em;
	position: relative;
	
	&__container {
		display: grid;
		//grid-template-columns: repeat(var(--columns), var(--card-offset));
		margin: 0;
		padding: 0;
	}
	
	&__center {
		justify-self: center;
	}
	
	&__card {
		width: var(--card-width);
		height: var(--card-height);
		cursor: pointer;
		border-radius: 1em;
		box-shadow: 0px 3px 5px 0.2em rgba(0, 0, 0, .4);
		background-color: white;
		background-size: 110% 110%;
		background-repeat: no-repeat;
		background-position: center center;
		grid-row: 1/2;
		margin: 0;
		will-change: transform, z-index, margin;
		transition: margin-top ease 200ms;
	}
	
	&__title, &__subtitle {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		margin-bottom: 0.8em;
		text-align: center;
	}
	
	&__title {
		font-family: var(--ffm-main);
		font-size: 1.6rem;
	}
	
	&__subtitle {
		font-family: var(--ffm-main);
		font-size: 1.2rem;
	}
	
	&__counter {
		position: absolute;
		right: 20%;
		top: 140%;
		height: 100%;
		width: max-content;

		
		
		&-title {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 1.3rem;
			width: 100%;
		}
	}
}

.dual {
	padding-left: 3em;
	display: grid;
	grid-template-columns: 0.6fr 10% 1fr;
	width: 100%;
	height: 100%;
	
	
	&__description {
		grid-column: 3/4;
		align-self: center;
		grid-row: 1/2;
		width: 80%;
		justify-self: flex-end;
	}
	
	&__description-1 {
		grid-column: 3/4;
		grid-row: 1/2;
		display: grid;
		grid-template-rows: 5% 0.8fr 5%;
		align-self: center;
		height: 100%;
	}
	
	&__details {
		grid-column: 1/2;
		align-self: center;
		justify-self: flex-end;
		position: relative;
	}
	
	&__details-1 {
		grid-column: 1/2;
		justify-self: flex-end;
		position: relative;
	}
	
	
	&__element-card {
		width: 21rem;
		height: 21rem;
		border-radius: 3em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	&-flip__container {
		position: absolute;
		display: grid;
		grid-template-columns: 8.5rem;
		grid-template-rows: 14.5rem;
		//width: 100%;
		//height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 4.25rem 7.25rem;
		will-change: transform;
		perspective: 600px;
		z-index: 1000;
		
	}
	
	&-flip-1, &-flip-2, &-flip-3 {
		top: 17%;
	}
	
	&-flip-1 {
		left: -4rem;
	}
	
	&-flip-2 {
		left: 6rem;
	}
	
	&-flip-3 {
		left: 16rem;
	}
	
	&-flip__card {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		grid-column: 1/2;
		grid-row: 1/2;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.5);
	}
	
	&-flip__card-selected {
		transform: scale(1.2);
	}
	
	&__label {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 0.8rem;
		position: absolute;
		bottom: -2rem;
		
		&-past {
			left: 0rem;
		}
		
		&-present {
			left: 9rem;
		}
		
		&-future {
			left: 19.5rem;
		}
	}
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.5rem;
		margin-bottom: 0.8em;
	}
	
	&__subtitle {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		color: var(--primary-color);
		margin-bottom: 1.5em;
	}
	
	&__nav {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 0.8rem;
		cursor: pointer;
		margin-bottom: 1em;
		
		
		&-description-container {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			height: 5rem;
			padding-right: 1em;
			-webkit-overflow-scrolling: touch
		}
		
		&-description {
			grid-column: 1/2;
			grid-row: 1/2;
			align-self: center;
		}
	}
	
	
	&__nav-container {
		width: 60%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.5em;
	}
	
	&__card {
		&-content {
			height: 16rem;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			
		}
		
		&-title {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 1.3rem;
			margin-bottom: 2em;
		}
		
		&-subtitle {
			font-family: var(--ffm-roboto);
			font-size: 0.85rem;
			color: var(--primary-color);
			margin-bottom: 3em;
		}
	}
	
	&__animation {
		will-change: opacity, transform;
		z-index: 1;
	}
	
	&__input {
		line-height: 4ch;
		background-image: linear-gradient(transparent, transparent calc(4ch - 1px), #FFFFFF 1px);
		background-size: 70% 4ch;
		border: none;
		background-color: transparent;
		outline: none;
		width: 100%;
		height: 100%;
		max-height: 17rem;
		resize: none;
		font-family: var(--ffm-roboto);
		color: var(--primary-color);
		font-size: 0.9rem;
		overflow: hidden;
		
		&::placeholder {
			font-family: var(--ffm-roboto);
			color: var(--primary-color);
			font-style: italic;
			font-size: 1rem;
		}
		
		&-counter {
			position: absolute;
			top: 99%;
			right: 0;
			padding: 0;
			margin: 0;
			font-weight: 400;
			font-family: var(--ffm-roboto);
			font-size: 0.8rem;
			color: var(--primary-color);
		}
		
		&-container {
			height: max-content;
			position: relative;
		}
	}
	
	&__text-main {
		display: grid;
		grid-template-columns: 1fr;
		height: 20rem;
		overflow-y: auto;
		margin-top: 2em;
		padding: 0 1em 0 0;
	}
}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.dual {
		display: grid;
		grid-template-columns:0.6fr 10% 1fr;
		width: 100%;
		height: 100%;
		
		&__element-card {
			width: 16rem;
			height: 16rem;
			border-radius: 1.5em;
		}
		
		&-flip__container {
			grid-template-columns: 6.5rem;
			grid-template-rows: 11rem;
			transform-origin: 3.25rem 5.5rem;
		}
		
		&-flip-1, &-flip-2, &-flip-3 {
			top: 15%;
		}
		
		&-flip-1 {
			left: -2.5rem;
		}
		
		&-flip-2 {
			left: 5rem;
		}
		
		&-flip-3 {
			left: 12.5rem;
		}
		
		&__nav-container {
			margin-bottom: 0;
			width: 80%;
		}
		
		&-flip__card {
			border-radius: 0.8em;
			
		}
		
		&__subtitle {
			margin-bottom: 1em;
		}
		
		&__label {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 0.8rem;
			position: absolute;
			bottom: -2rem;
			
			&-past {
				left: 0;
			}
			
			&-present {
				left: 7.5rem;
			}
			
			&-future {
				left: 15.5rem;
			}
		}
		
		
	}
}

.header {
	padding: 1em 0 0;
	display: grid;
	grid-template-columns: 30% 70%;
	width: 100%;
	height: 100%;
	grid-row: 1/2;
	align-items: center;
	
	&__logo {
		width: 10rem;
		height: 5rem;
		padding: 0;
		margin: 0;
		cursor: pointer;
		
		&-container {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0;
			margin: 0;
			
		}
		
		&-text {
			font-size: 1.5rem;
			font-family: var(--ffm-main);
			font-weight: 400;
			color: var(--primary-color);
		}
	}
}

.nav {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	padding: 0;
	
	&__link {
		font-size: 0.7rem;
		font-family: var(--ffm-main);
		font-weight: 400;
		color: var(--primary-color);
		
		&-disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
		
	}
	
	&__slide {
		width: 30px;
		height: 4px;
		background-color: var(--primary-color);
		position: absolute;
	}
}



@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.header {
		grid-template-columns: 25% 75%;
	}
	
	nav {
		display: flex;
		justify-content: space-around;
	}
}

.notification-container {
	font-size: 14px;
	position: fixed;
	z-index: 999999
}

.top-left {
	bottom: 20px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}


.notification {
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	padding: 0 30px;
	width: 250px;
	max-height: 120px;
	border-radius: 3px 3px 3px 3px;
	color: #000;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	//background: #fff no-repeat 15px;
}

.notification-title {
	font-weight: 700;
	font-size: 12px;
	text-align: left;
	margin-top: 0;
	width: 300px;
	height: auto;
}


.toast {
	height: 50px;
	width: 365px;
	color: #8930E1;
	//padding: 20px 15px 10px 10px;
}

.notification-container button {
	position: relative;
	font-weight: 700;
	color: #8930E1;
	outline: none;
	border: none;
	opacity: .8;
	line-height: 1;
	font-size: 16px;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0
}

@keyframes toast-in-right {
	from {
		opacity: 0;
		
	}
	to {
		opacity: 1
	}
}

@keyframes toast-in-left {
	from {
		opacity: 0;
		
	}
	to {
		opacity: 1
	}
}

.dual-flip {
	padding-left: 3em;
	display: grid;
	grid-template-columns:0.6fr 10% 1fr;
	width: 100%;
	height: 100%;
	
	
	&__description {
		grid-column: 3/4;
		align-self: center;
		width: 80%;
		justify-self: flex-end;
		grid-row: 1/2;
	}
	
	&__details {
		grid-column: 1/2;
		align-self: center;
		justify-self: flex-end;
		position: relative;
	}
	
	&__details-1 {
		grid-column: 1/2;
		justify-self: flex-end;
		position: relative;
	}
	
	
	&__element-card {
		width: 21rem;
		height: 21rem;
		border-radius: 3em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	&-flip__container {
		position: absolute;
		display: grid;
		grid-template-columns: 8.5rem;
		grid-template-rows: 14.5rem;
		//width: 100%;
		//height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 4.25rem 7.25rem;
		will-change: transform;
		perspective: 600px;
		z-index: 10;
		
	}
	
	&-flip-1, &-flip-2, &-flip-3 {
		top: 17%;
	}
	
	&-flip-1 {
		left: -4rem;
	}
	
	&-flip-2 {
		left: 6rem;
	}
	
	&-flip-3 {
		left: 14rem;
	}
	
	&-flip__card {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		grid-column: 1/2;
		grid-row: 1/2;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.5);
	}
	
	&-flip__card-selected {
		transform: scale(1.2);
	}
	
	&__label {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 0.8rem;
		position: absolute;
		bottom: -2rem;
		
		&-past {
			left: 0rem;
		}
		
		&-present {
			left: 9rem;
		}
		
		&-future {
			left: 17rem;
		}
	}
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.5rem;
		margin-bottom: 0.8em;
	}
	
	&__subtitle {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		color: var(--primary-color);
		margin-bottom: 1.5em;
	}
	
	&__nav {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 0.8rem;
		cursor: pointer;
		margin-bottom: 1em;
	}
	
	
	&__nav-container {
		width: 60%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.8em;
	}
	
	&__icon {
		width: 2.3rem;
		height: 2.3rem;
		margin-top: 2em;
		cursor: pointer;
		position: absolute;
		bottom: -2rem;
		z-index: 100;
	}
	
	&__1 {
		left: -1rem;
	}
	
	&__2 {
		left: 9rem;
	}
	
	&__3 {
		left: 19.5rem;
	}
	
	&__input {
		line-height: 4ch;
		background-image: linear-gradient(transparent, transparent calc(4ch - 1px), #FFFFFF 0px);
		background-size: 90% 4ch;
		border: none;
		background-color: transparent;
		outline: none;
		width: 100%;
		height: 100%;
		resize: none;
		font-family: var(--ffm-roboto);
		color: var(--primary-color);
		font-size: 0.9rem;
		overflow: hidden;
		margin: 0;
		
		&::placeholder {
			font-family: var(--ffm-roboto);
			color: var(--primary-color);
			font-style: italic;
			font-size: 1rem;
		}
		
		&-counter {
			position: absolute;
			top: 95%;
			right: 0;
			padding: 0;
			margin: 0;
			font-weight: 400;
			font-family: var(--ffm-roboto);
			font-size: 0.9rem;
			color: var(--primary-color);
		}
		
		&-container {
			height: 70%;
			position: relative;
			margin: 0;
			padding: 0;
		}
	}
}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.dual-flip {
		&__1 {
			left: -0.5rem;
		}
		
		&__2 {
			left: 7.5rem;
		}
		
		&__3 {
			left: 15.5rem;
		}
		
	}
}

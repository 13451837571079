.conclusion {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-column-gap: 2em;
	align-items: center;
	height: 100%;
	justify-items: center;
	position: relative;
	z-index: 20;
	
	&__container {
		display: grid;
		grid-template-rows: 2rem 7rem 1fr;
		grid-row-gap: 1em;
	}
	
	
	&__title, &__label {
		color: var(--primary-color);
		font-family: var(--ffm-main);
	}
	
	&__title {
		font-size: 1.6rem;
		text-align: center;
	}
	
	&__label {
		text-align: right;
		font-size: 0.7rem;
	}
	
	&__label-left {
		text-align: left;
	}
	
	
	&__div-1 {
		height: 13rem;
		width: 100%;
		z-index: 20;
	}
	
	&__element-container {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: -1;
	}
	
	&__icon {
		width: 1.3rem;
		height: 1.3rem;
		margin: 1em 0.35em;
	}
	
	&__element-card {
		width: 17rem;
		height: 17rem;
		border-radius: 3em;
		background-position: 100% 100%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	&-flip__container {
		display: grid;
		grid-template-columns: 9rem;
		grid-template-rows: 15rem;
		//width: 100%;
		//height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 4.5rem 7.5rem;
		will-change: transform;
		perspective: 600px;
		z-index: 10;
		position: relative;
	}
	
	&__shadow {
		display: grid;
		grid-template-columns: 9rem;
		grid-template-rows: 15rem;
		transform-style: preserve-3d;
		transform-origin: 4.5rem 7.5rem;
		will-change: transform, opacity, top, left;
		perspective: 600px;
		z-index: 100;
		position: absolute;
		grid-column: 4/5;
		grid-row: 1/2;
		pointer-events: none;
		//top: -30%;
		//left: -10%;
	}
	
	&-flip__card {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		grid-column: 1/2;
		grid-row: 1/2;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.5);
	}
	
	
	&__icon-container {
		width: 100%;
		cursor: pointer;
		position: absolute;
		bottom: -5rem;
		z-index: 100;
		display: flex;
		justify-content: center;
	}
	
	&__icon-flip {
		width: 2.3rem;
		height: 2.3rem;
		cursor: pointer;
	}
}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.conclusion {
		
		grid-column-gap: 0.5em;
		
		&__container {
			display: grid;
			grid-template-rows: 2rem 7.5rem 1fr;
			
		}
		
		&__icon {
			width: 1.3rem;
			height: 1.3rem;
			margin: 0 0.1em;
		}
		
		&__label-left {
			margin-bottom: 1em;
		}
		
	}
}

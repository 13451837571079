.three {
	display: grid;
	grid-template-columns:0.8fr 5% 1fr;
	width: 100%;
	height: 100%;
	
	&__deck {
		grid-column: 1/2;
		justify-self: flex-end;
		align-self: center;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 2em;
	}
	
	
	&__details {
		grid-column: 3/4;
		justify-self: flex-start;
		align-self: center;
		animation: transitLeft 1000ms ease-in-out;
	}
	
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 1.1rem;
		margin-bottom: 2em;
		color: var(--primary-color);
	}
	
	
	&__title, &__subtitle, &__label {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		
	}
	
	&__title {
		font-size: 1.3rem;
		margin-bottom: 0.8em;
	}
	
	&__subtitle {
		font-size: 1rem;
		margin-bottom: 0.8em;
	}
	
	&__label {
		font-size: 0.8rem;
		margin-top: 2em;
		text-align: center;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 0.9rem;
		margin-bottom: 2em;
		color: var(--primary-color);
	}
}

@keyframes transitLeft {
	0% {
		transform: translateX(50px);
	}
	100% {
		transform: translateX(0px);
	}
}


@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.three {
		
		&__deck {
			grid-column: 1/2;
			justify-self: flex-end;
			align-self: center;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 2em;
		}
		
		
		&__details {
			height: 25rem;
			overflow-y: auto;
			
		}
	}
}

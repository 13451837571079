.disclaimer {
	display: grid;
	justify-items: center;
	width: 100%;
	height: 100%;
	align-items: center;
	
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.6rem;
		text-align: center;
		align-self: center;
		margin-bottom: 2em;
		position: absolute;
		top: -30%;
		width: 100%;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		margin: 0 0 1em;
		color: var(--primary-color);
		text-align: center;
		padding: 0;
	}
	
	&__para {
		align-self: center;
		width: 40%;
		position: relative;
	}
	
}

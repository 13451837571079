.App {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: minmax(10px, 4fr) 1200px minmax(10px, 4fr);
	place-items: center;

	
	&__main {
		grid-column: 2/3;
		display: grid;
		grid-template-rows: minmax(3rem, 5rem) 3em 1fr 3rem;
		grid-template-columns: 1fr;
		grid-row-gap: 0;
		width: 100%;
		padding: 0;
		margin: 0;
		height: 100%;
		place-self: center;
	}
	
	&-content {
		grid-row: 3/4;
		grid-column: 1/2;
		width: 100%;
		height: 100%;
	}
}


@media (max-width: 770px) {
	.App {
		display: none;
	}
}

@media (min-width: 771px) {
	.App {
		display: grid;
	}
}


@media only screen and (min-device-width: 771px) and (max-device-width: 1150px) {
	.App {
		grid-template-columns: minmax(1px, 1em) 1fr minmax(1px, 1em);
	}
}


.card-deck {
	&__container {
		display: grid;
		cursor: pointer;
		margin: 0;
		padding: 0;
	}
	
	&__card {
		width: 100%;
		height: 100%;
		cursor: pointer;
		border-radius: 1em;
		box-shadow: var(--bs-primary);
		//background-size: cover;
		//background-repeat: no-repeat;
		//background-position: center center;
		grid-row: 1/2;
		margin: 0;
		padding: 0;
		object-fit: fill;
		object-position: 100% 100%;
		will-change: transform;
	}
}

.element {
	display: grid;
	grid-template-columns: var(--play-columns);
	grid-column-gap: 2em;
	width: 100%;
	height: 100%;
	margin: 0;
	
	&__cards {
		justify-content: flex-end;
		align-items: center;
		align-self: center;
		display: grid;
		grid-column: 1/2;
		grid-template-columns: repeat(2, 10rem);
		grid-template-rows: repeat(2, 10rem);
		grid-gap: 0.8rem;
		
	}
	
	&__card {
		width: 100%;
		height: 100%;
		cursor: pointer;
		border-radius: 1em;
		box-shadow: var(--bs-primary);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		transform-origin: left bottom;
		background-image: url("../../../Assets/cardBack.png");
		margin: 0;
		padding: 0;
		will-change: transform;
		transition: opacity ease-in-out 1000ms;
		
		&-disabled {
			opacity: 0.5;
		}
	}
	
	&__details {
		width: 75%;
		grid-column: 3/4;
		align-self: center;
	}
	
	&__title, &__description {
		color: var(--primary-color);
		
	}
	
	
	&__title {
		font-family: var(--ffm-main);
		font-size: 1.8rem;
		margin-bottom: 1em;
		width: 80%;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		margin-bottom: 1em;
	}
	
	&-question {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	
}

.element-flip {
	
	&__root {
		justify-self: flex-end;
		align-self: center;
	}
	
	&__container {
		display: grid;
		grid-template-columns: 21rem;
		grid-template-rows: 21rem;
		width: 100%;
		height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 11rem 11rem;
		will-change: transform;
		perspective: 600px;
	}
	
	&__card {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		grid-column: 1/2;
		grid-row: 1/2;
		border-radius: 3em;
		background-position: 100% 100%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	
	&__details {
		width: 75%;
		grid-column: 3/4;
		align-self: center;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		height: 70%;
		max-height: 30rem;
	}
	
	&__sub-details {
		width: 100%;
		height: 100%;
		grid-column: 1/2;
		grid-row: 1/2;
	}
	
	&__input {
		line-height: 4ch;
		background-image: linear-gradient(transparent, transparent calc(4ch - 1px), #FFFFFF 1px);
		background-size: 70% 4ch;
		border: none;
		background-color: transparent;
		outline: none;
		width: 100%;
		height: 90%;
		max-height: 17rem;
		resize: none;
		font-family: var(--ffm-roboto);
		color: var(--primary-color);
		font-size: 0.9rem;
		overflow: hidden;
		margin: 1em 0 0;
		
		&::placeholder {
			font-family: var(--ffm-roboto);
			color: var(--primary-color);
			font-style: italic;
			font-size: 1rem;
		}
		
		&-counter {
			position: absolute;
			top: 95%;
			right: 0;
			padding: 0;
			margin: 0;
			font-weight: 400;
			font-family: var(--ffm-roboto);
			font-size: 1rem;
			color: var(--primary-color);
		}
		
		&-container {
			height: 70%;
			position: relative;
		}
	}
	
}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.element-flip {
		&__container {
			grid-template-columns: 18rem;
			grid-template-rows: 18rem;
			justify-self: center;
		}
		
		&__details {
			max-height: 25rem;
		}
		
		
		&__input {
			max-height: 14rem;
		}
	}
	
}

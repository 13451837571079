.show-shadow {
	display: grid;
	grid-template-columns:0.6fr 10% 1fr;
	width: 100%;
	height: 100%;
	padding-left: 3em;
	
	
	&__description {
		grid-column: 3/4;
		align-self: center;
		grid-row: 1/2;
	}
	
	&__details {
		grid-column: 1/2;
		align-self: center;
		justify-self: flex-end;
		position: relative;
	}
	
	&__details-1 {
		grid-column: 1/2;
		justify-self: flex-end;
		position: relative;
	}
	
	
	&__element-card {
		width: 21rem;
		height: 21rem;
		border-radius: 3em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	&__container {
		position: absolute;
		display: grid;
		grid-template-columns: 8.5rem;
		grid-template-rows: 14.5rem;
		//width: 100%;
		//height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 4.25rem 7.25rem;
		will-change: transform;
		perspective: 600px;
		z-index: 1000;
		
	}
	
	&__card-1, &__card-2, &__card-3 {
		top: 17%;
	}
	
	&__card-1 {
		left: -4rem;
	}
	
	&__card-2 {
		left: 6rem;
	}
	
	&__card-3 {
		left: 16rem;
	}
	
	&__card-4 {
		top: -10%;
		left: 6rem;
	}
	
	&__desc {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		margin-top: 2em;
		color: var(--primary-color);
	}
	
	&__card {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		grid-column: 1/2;
		grid-row: 1/2;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.5);
	}
	
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.5rem;
		margin-bottom: 0.1em;
		margin-left: 0.4em;
	}
}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.show-shadow {
		&__element-card {
			width: 17rem;
			height: 17rem;
			border-radius: 1em;
		}
		
		&__card-1, &__card-2, &__card-3 {
			top: 20%;
		}
		
		&__card-1 {
			left: -3rem;
		}
		
		&__card-2 {
			left: 5.5rem;
		}
		
		&__card-3 {
			left: 13.8rem;
		}
		
		&__card-4 {
			top: -10%;
			left: 6rem;
		}
		
		
		&__container {
			
			grid-template-columns: 7rem;
			grid-template-rows: 12rem;
			//width: 100%;
			//height: 100%;
			
			transform-origin: 3.5rem 6rem;
			will-change: transform;
			
			
		}
	}
	
	
}

.choose {
	&__container {
		width: 100%;
		display: grid;
		height: 100%;
		grid-template-rows: 2rem 2rem 1fr;
	}
	
	&__heal-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 1em;
		will-change: opacity, transform;
		align-self: center;
		align-items: center;
		justify-items: center;
		//margin-top: 6em;
		//position: relative;
	}
	
	&__item {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1.5rem 10.5rem 3rem;
		grid-column-gap: 1em;
	}
	
	&__heading, &__title, &__description, &__item-title, &__refresh, &__warning {
		color: var(--primary-color);
		height: max-content;
	}
	
	&__title, &__heading, &__refresh {
		font-family: var(--ffm-main);
		font-weight: 400;
	}
	
	&__title {
		text-align: center;
		font-size: 1.9rem;
		//position: absolute;
		//top: -50%;
		width: 100%;
	}
	
	&__heading {
		font-size: 0.8rem;
	}
	
	&__deck {
		grid-column: 1/2;
		grid-row: 1/4;
		height: 100%;
	}
	
	&__item-title {
		grid-row: 1/2;
		grid-column: 2/3;
		font-family: var(--ffm-roboto);
		font-weight: 200;
		font-size: 0.7rem;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-weight: 200;
		font-size: 0.7rem;
		grid-column: 2/3;
		grid-row: 2/3;
		margin: 0;
		padding: 0;
	}
	
	&__btn {
		grid-row: 3/4;
		grid-column: 2/3;
		align-self: flex-end;
		margin: 0;
		padding: 0;
		will-change: transform;
	}
	
	&__refresh-container {
		
		width: 50%;
		//grid-row: 2/3;
		//grid-column: 1/2;
		justify-self: center;
		display: grid;
		grid-template-rows: 10% 75% 5%;
		justify-items: center;
		will-change: opacity, transform;
		align-self: flex-start;
		align-items: center;
		height: 75%;
		margin-top: 2em;
	}
	
	&__refresh {
		font-size: 1.5rem;
		text-align: center;
	}
	
	&__warning {
		font-family: var(--ffm-roboto);
		font-size: 1.15rem;
		width: 70%;
		text-align: center;
		
	}
	
	
}

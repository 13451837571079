.positivity {
	display: grid;
	grid-template-columns:0.6fr 10% 1fr;
	width: 100%;
	height: 100%;
	position: absolute;
	
	
	&__deck {
		grid-column: 1/2;
		justify-self: center;
		align-self: center;
	}
	
	&__content {
		overflow-y: auto;
		min-height: 8rem;
		height: 100%;
		max-height: 20rem;
	}
	
	&__container {
		position: relative;
		width: 100%;
		height: 100%;
	}
	
	&__details {
		height: 100%;
		max-height: 40rem;
		grid-column: 3/4;
		padding-top: 2em;
		display: grid;
		grid-template-rows: 5% 1fr 10%;
		align-self: center;
	}
	
	&__details-1 {
		grid-column: 3/4;
		padding-top: 2em;
		align-self: center;
		width: 90%;
		justify-self: flex-end;
	}
	
	&__text-main {
		position: relative;
	}
	
	&__text-container {
		padding: 2em 0 0 0;
		position: absolute;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		margin-bottom: 2em;
		color: var(--primary-color);
	}
	
	&__explore {
		flex-direction: column;
		//justify-content: center;
		//align-items: center;
		align-self: center;
		justify-self: center;
		grid-row: 2/3;
		width: 70%;
	}
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.6rem;
		margin-bottom: 0.8em;
		width: 80%;
	}
	
	&__subtitle {
		color: var(--primary-color);
		font-family: var(--ffm-roboto);
		font-size: 1.2rem;
		margin-bottom: 0.8em;
		font-weight: 600;
	}
	
	
	&-flip__container {
		display: grid;
		grid-template-columns: 12rem;
		grid-template-rows: 20rem;
		width: 100%;
		height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 6rem 10rem;
		will-change: transform;
		perspective: 600px;
		z-index: 1000;
	}
	
	&-flip__card {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		grid-column: 1/2;
		grid-row: 1/2;
		border-radius: 1em;
		background-position: 100% 100%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
}

.duality-shuffle {
	display: grid;
	grid-template-columns:0.8fr 5% 1fr;
	width: 100%;
	height: 100%;
	
	&__deck {
		grid-column: 1/2;
		justify-self: center;
		align-self: center;
		animation: transitRight 1000ms ease-in-out;
	}
	
	&__details {
		grid-column: 3/4;
		justify-self: flex-start;
		align-self: center;
		width: 80%;
		animation: transitLeft 1000ms ease-in-out;
	}
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.1rem;
		margin-bottom: 2em;
		
	}
	
	&__title-1 {
		word-break: break-word;
	}
}

@keyframes transitLeft {
	0% {
		transform: translateX(50px);
	}
	100% {
		transform: translateX(0px);
	}
}


@keyframes transitRight {
	0% {
		transform: translateX(-50px);
	}
	100% {
		transform: translateX(0px);
	}
}


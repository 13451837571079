.reading {
	&__container {
		display: grid;
		grid-template-columns: 2fr 1fr;
		height: 100%;
		width: 100%;
	}
	
	&__heal {
		align-self: center;
		display: grid;
		width: 85%;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 1.5em;
	}
	
	&__label {
		color: var(--primary-color);
		font-size: 0.8rem;
		font-family: var(--ffm-main);
		font-weight: 400;
		margin-top: 2em;
		width: 10rem;
		text-align: center;
	}
	
	&__description {
		align-self: center;
		height: 50%;
	}
	
	&__title, &__subtitle, &__text {
		color: var(--primary-color);
	}
	
	&__title {
		font-size: 1.8rem;
		font-family: var(--ffm-main);
		font-weight: 400;
		margin-bottom: 1em;
	}
	
	&__subtitle {
		font-family: var(--ffm-roboto);
		font-weight: 200;
		font-size: 1.1rem;
		margin-bottom: 2em;
	}
	
	&__text {
		font-family: var(--ffm-roboto);
		font-weight: 200;
		font-size: 1rem;
	}
}


.journal {
	height: 100%;
	

	
	&__title-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 2em;
	}
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.5rem;
	}
	
	&__icons {
		display: flex;
	}
	
	&__icon {
		width: 1.5rem;
		height: 1.5rem;
		margin: 0 0.2em;
	}
	
	&__btn {
		cursor: pointer;
		padding: 1.4em 1.4em;
		width: auto;
		font-family: var(--ffm-main);
		color: var(--clr-purlple);
		background-color: var(--accent-color);
		font-size: 0.8rem;
		border: none;
		border-radius: 1rem;
		margin: 0;
		line-height: 0;
		outline: none;
	}
	
	&__subtitle {
		color: var(--primary-color);
		font-family: var(--ffm-roboto);
		font-size: 1.2rem;
		text-align: center;
		margin: 3em auto 0;
		width: 50%;
	}
	
	&__main-card {
		display: grid;
		grid-template-columns: repeat(4, 1fr) 4% repeat(4, 1fr);
		grid-column-gap: 1em;
		align-items: center;
		height: 100%;
		justify-items: center;
		position: relative;
		z-index: 20;
		margin: 6em 0;
	}
	
	
	&__div-1 {
		height: 13.5rem;
		width: 100%;
		z-index: 20;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		grid-row: 1/2;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
		
	}
	
	&__div-2 {
		height: 12rem;
		width: 100%;
		grid-column: 5/6;
		grid-row: 1/2;
	}
	
	&__div-empty {
		height: 12rem;
		width: 100%;
		grid-column: 1/3;
		grid-row: 1/2;
	}
	
	&__element-container {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: -1;
	}
	
	
	&__element-card {
		width: 17rem;
		height: 17rem;
		border-radius: 2em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
		
		
	}
	
	&__section {
		display: grid;
		grid-template-columns: 5% 1fr 5%;
		align-items: center;
		height: auto;
		margin-bottom: 4em;
		
		&-border {
			height: 3px;
			width: 75%;
			background-color: var(--primary-color);
		}
		
		&-main {
			display: flex;
			justify-content: space-between;
			background: rgb(74, 165, 226);
			background: linear-gradient(90deg, rgba(74, 165, 226, 1) 0%, rgba(0, 82, 235, 1) 87%);
			padding: 1.5em 2em;
		}
	}
	
	&__question {
		color: var(--primary-color);
		font-family: var(--ffm-roboto);
		font-size: 1.3rem;
		text-align: center;
		margin: 3em auto;
		width: 50%;
		font-weight: 600;
		word-break: break-word;
	}
	
	&__grid {
		margin: 8em 0;
		display: grid;
		grid-template-columns: 0.8fr 1fr;
		grid-column-gap: 2em;
		
		&-element, &-chakra {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			justify-self: center;
			box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
		}
		
		
		&-element {
			width: 18rem;
			height: 18rem;
			border-radius: 2em;
		}
		
		&-intro {
			grid-column: 1/2;
			display: grid;
			justify-items: center;
			
		}
		
		&-chakra {
			width: 10rem;
			height: 17rem;
			border-radius: 1em;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		
		&-details {
			justify-self: flex-start;
		}
		
		&-subtitle {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 0.9rem;
			margin-bottom: 1em;
		}
		
		&-title {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 1.1rem;
			margin-bottom: 2em;
		}
		
		&-feel {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 0.9rem;
			margin-bottom: 4em;
			word-break: break-all;
			
		}
		
		&-desc {
			color: var(--primary-color);
			font-family: var(--ffm-roboto);
			font-size: 0.9rem;
			margin-bottom: 1em;
		}
	}
	
	
	&__grid-2 {
		margin: 8em 0;
		display: grid;
		grid-template-columns: 1fr 0.5fr 1.2fr;
		grid-column-gap: 1.4em;
		align-items: center;
		
		
		&-intro {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 1em;
		}
		
		&-subtitle {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 1rem;
			margin-bottom: 1em;
		}
		
		&-title {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 1.2rem;
			margin-bottom: 2em;
		}
		
		&-feel {
			color: var(--primary-color);
			font-family: var(--ffm-main);
			font-size: 0.9rem;
			margin-bottom: 4em;
			word-break: break-all;
			
		}
		
		&-desc {
			color: var(--primary-color);
			font-family: var(--ffm-roboto);
			font-size: 0.8rem;
			margin-bottom: 1em;
		}
		
	}
	
	&__placeholder {
		height: 15rem;
		width: 9rem;
		position: relative;
		border-radius: 0.8em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
		
		
		&-label-container {
			position: absolute;
			display: flex;
			justify-content: center;
			width: 100%;
			bottom: -2rem;
		}
		
		&__ball {
			top: -30%;
			left: -10%;
			height: 15rem;
			width: 9rem;
			position: absolute;
			border-radius: 0.8em;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url("../../../Assets/ShadowBall.png");
			box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
		}
	}
	
	&__placeholder-1 {
		height: 18rem;
		width: 11rem;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
		
	}
	
	&__placeholder-2 {
		height: 18rem;
		width: 11rem;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
		
	}
	
	
	&__grid-3 {
		margin: 8em 0;
		display: grid;
		grid-template-columns:  1.2fr 0.5fr  1fr;
		grid-column-gap: 1.4em;
		align-items: center;
		
		&-intro {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 1em;
			position: relative;
			z-index: 10;
		}
		
		
		&-element-container {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: -1;
			border-radius: 1em;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		
		&-element-card {
			width: 18rem;
			height: 18rem;
			border-radius: 1.5em;
			background-position: 100% 100%;
			background-repeat: no-repeat;
			background-size: cover;
			box-shadow: 5px 3px 10px 0.3em rgba(0, 0, 0, 0.3);
			
		}
	}
	
	&__shadow {
		display: grid;
		grid-template-columns: 0.9fr 1fr;
		grid-column-gap: 5em;
		position: relative;
		margin: 7rem 0;
		
		&-intro {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 2em;
			margin-top: 5rem;
		}
		
		&-intro-1 {
			width: 100%;
			height: 100%;
			display: grid;
			place-items: center;
			
		}
	}
	
	&__print {
		display: grid;
		grid-template-columns: repeat(2, 1fr) 2%  repeat(2, 1fr) 2% repeat(2, 1fr);
		grid-column-gap: 2em;
		margin: 8em 0;
	}
	
	&__hr {
		height: 3px;
		background-color: var(--primary-color);
	}
	
	&__padding {
		padding: 2em;
	}
}


@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.journal {
		height: 100%;
		width: 100%;
		padding: 1em;
		
		
		&__main-card {
			grid-template-columns: repeat(4, 0.5fr) 1% repeat(4, 0.5fr);
			grid-column-gap: 0.5em;
			margin: 3em 0;
			align-items: center;
			justify-items: center;
			height: 100%;
			justify-self: center;
			align-self: center;
			
		}
		
		
		&__div-1 {
			height: 13rem;
			align-self: center;
		}
		
		&__element-card {
			width: 16rem;
			height: 16rem;
			border-radius: 2em;
		}
		
		&__element-container {
			position: absolute;
			height: 100%;
			align-self: center;
			align-items: center;
			justify-self: center;
		}
		
		&__placeholder {
			height: 12rem;
			width: 7rem;
			
			
			&__ball {
				top: -30%;
				left: -10%;
				height: 12rem;
				width: 7rem;
			}
		}
	}
	
}


.show-chakra {
	background-image: url("../../../Assets/chakraBackground-2.png");
	background-repeat: no-repeat;
	background-size: auto 90%;
	background-position: center bottom;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	background-color: #2C0061;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100000;
	
	&__btn {
		cursor: pointer;
		padding: 0.8em 2em;
		font-family: var(--ffm-main);
		color: var(--clr-purlple);
		background-color: var(--accent-color);
		font-size: 0.75rem;
		border: none;
		border-radius: 1rem;
		height: 1.7rem;
		line-height: 0;
		outline: none;
		width: max-content;
		
		&-container {
			top: 10%;
			position: absolute;
			width: 100vw;
			display: flex;
			justify-content: center;
		}
	}
}

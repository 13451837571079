:root {
	--element-card: 15rem
}

.home {
	width: 100%;
	height: 100%;
	display: grid;
	grid-row: 3/4;
	grid-template-columns: 50% 50%;
	grid-column-gap: 2em;
	justify-content: space-between;
	
	&__details {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-rows: 85% 15%;
		grid-column: 1/2;
	}
	
	&__animation {
		padding: 1em;
		justify-self: flex-end;
		grid-column: 2/3;
		align-self: center;
		display: grid;
		grid-template-columns: var(--element-card) var(--element-card);
		grid-template-rows: var(--element-card) var(--element-card);
		grid-gap: 1.3em;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-items: flex-end;
		position: relative;
		z-index: 1;
		
	}
	
	&__element-card {
		object-fit: cover;
		width: 100%;
		height: 100%;
		will-change: transform;
	}
}

.collapsing__card {
	position: absolute;
	width: 7rem;
	height: 12rem;
	box-shadow: var(--bs-primary);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 0;
	margin: 0;
	will-change: top, left;
}

.collapsing__card-rotate-animation {

}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.home {
		grid-template-columns: 1fr 1fr;
		height: 100%;
		
		
		&__animation {
			grid-template-columns: 12rem 12rem;
			grid-template-rows: 12rem 12rem;
			grid-gap: 0.5em;
			padding: 0;
			align-self: center;
			height: auto;
			
		}
		
		.collapsing__card {
			width: 6rem;
			height: 10rem;
		}
		
	}
}

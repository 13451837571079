.footer {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
	grid-row: 4/5;
	padding-bottom: 1em;
	
	&__share {
		display: flex;
		width: 15rem;
		justify-content: space-between;
		align-items: center;
	}
	
	&__text {
		font-weight: 400;
		font-family: var(--ffm-main);
		font-size: 0.65rem;
		color: var(--primary-color);
	}
	

}

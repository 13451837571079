.play {
	display: grid;
	width: 100%;
	grid-template-rows: 1fr 5rem;
	height: 100%;
	grid-row-gap: 1em;
	
	&__journal {
		grid-row: 1/3;
		height: 100%;
		width: 100%;
	}
}


@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.play {
		&__journal {
			
			width: 100vw;
		}
	}
}

.question {
	
	&__container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 2em;
		width: 98%;
		margin: 1em auto 0;
		height: 100%;
		will-change: opacity;
	}
	
	&__example-title, &__example, &__title, &__description {
		color: var(--primary-color);
	}
	
	&__example-container {
		width: 90%;
	}
	
	&__example-title {
		font-weight: 400;
		font-family: var(--ffm-main);
		font-size: 1.2rem;
	}
	
	&__example {
		font-weight: 400;
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		margin-top: 2em;
	}
	
	&__sub-container {
		display: grid;
		grid-template-rows: 0.4fr 0.7fr 1.5fr;
		height: 100%;
		max-height: 30rem;
		width: 90%;
	}
	
	&__title {
		font-weight: 400;
		font-family: var(--ffm-main);
		font-size: 1.7rem;
	}
	
	&__description {
		font-weight: 400;
		font-family: var(--ffm-roboto);
		font-size: 0.95rem;
		margin-bottom: 1.3em;
	}
	
	
	&__input {
		line-height: 4ch;
		background-image: linear-gradient(transparent, transparent calc(4ch - 1px), #FFFFFF 0px);
		background-size: 90% 4ch;
		border: none;
		background-color: transparent;
		outline: none;
		width: 100%;
		height: 100%;
		resize: none;
		font-family: var(--ffm-roboto);
		color: var(--primary-color);
		font-size: 0.9rem;
		overflow: hidden;
		margin-top: 0.1em;
		
		&::placeholder {
			font-family: var(--ffm-roboto);
			color: var(--primary-color);
			font-style: italic;
			font-size: 1rem;
		}
		
		&-counter {
			position: absolute;
			top: 97%;
			right: 0;
			padding: 0;
			margin: 0;
			font-weight: 400;
			font-family: var(--ffm-roboto);
			font-size: 1rem;
			color: var(--primary-color);
		}
		
		&-container {
			height: 70%;
			position: relative;
		}
	}
	
	
}


@media only screen and (min-width: 772px) and (max-width: 1150px){
	.question__input-counter {
		top: 95%;
	}
}

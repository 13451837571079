
html, body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


p, div, img, a, h1, h2, h3, h4, h5, span {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

*, *::after, *::before {
    box-sizing: border-box;
}

:root {


    --ffm-main: Futura, sans-serif;
    --ffm-roboto: Roboto, sans-serif;
    --primary-color: #FFFFFF;
    --accent-text: #575757;
    --accent-color: #F8E71C;
    --clr-purlple: #8930E1;
    --transistion-bg: background-color 200ms ease-in-out;
    --transistion-color: color 200ms ease-in-out;
    --bs-primary: 5px 3px 6px 0.1em rgba(0, 0, 0, .3);
    --play-columns: 0.8fr 10% 1fr;
}


body, #root, .App {
    height: 100vh;
    width: 100vw;

}

/*body, #root, .App {*/
/*    height: 100vh;*/
/*}*/

body {
    height: 100vh;
    background: linear-gradient(90deg, rgba(0, 76, 255, 1) 0%, rgba(173, 0, 255, 1) 97%);
    position: relative;
    overflow-x: hidden;
}

.w-100 {
    width: 100%;
}

.card-animation {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.spinner-container {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
}


.h-100 {
    height: 100%;
}

.h-50 {
    height: 50%;
}

.h-25 {
    height: 25%;
}

.h-90 {
    height: 90%;
}

.h-auto {
    height: auto;
}

.d-flex {
    display: flex;
}

.justify-content-between {
    align-items: center;
    justify-content: space-between;
}

.justify-content-evenly {
    align-items: center;
    justify-content: space-evenly;
}


.justify-content-center {
    align-items: center;
    justify-content: center;
}


.w-25 {
    width: 25%;
}

.w-20 {
    width: 20%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}


.w-75 {
    width: 75%;
}

.w-90 {
    width: 90%;
}

.w-auto {
    width: auto;
}

.w-100 {
    width: 100%;
}

.margin-auto {
    margin: 0 auto;
}

.container-main {
    padding: 2em 1em;
    width: 100%;
    height: 100%;
    grid-column: 2/3;
}


.close {
    display: grid;
    justify-items: flex-end;
    align-items: center;
    width: 100%;
}

.close__img {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}


.flex-start {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.btn {
    cursor: pointer;
    padding: 0.8em 0;
    width: 100%;
    font-family: var(--ffm-main);
    color: var(--clr-purlple);
    background-color: var(--accent-color);
    font-size: 0.75rem;
    border: none;
    border-radius: 1rem;
    margin: 0;
    height: 1.7rem;
    line-height: 0;
    outline: none;
}

.btn-outline {
    color: var(--accent-color);
    background-color: transparent;
    border: 2px solid var(--accent-color)
}

.play__main-heading {
    color: var(--primary-color);
    font-family: var(--ffm-main);
    font-weight: 400;
    font-size: 0.8rem;
}

/*.btn-outline:hover:not(:disabled) {*/
/*	background: var(--accent-color);*/
/*	color: var(--secondary-color);*/
/*}*/

.btn:disabled {
    /*background-color: var(--disabled-color);*/
    cursor: not-allowed;
    opacity: 0.7;
}

.main__title {
    color: var(--primary-color);
    font-family: var(--ffm-main);
    font-size: 1.6rem;
    margin-bottom: 0.8em;
    width: 80%;
}

.main__description {
    font-family: var(--ffm-roboto);
    font-size: 1rem;
    margin-bottom: 0.8em;
    color: var(--primary-color);
}
/* Scrollbar  */

::-webkit-scrollbar {
	max-width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
    background-color: #8930e1;
    opacity: 0.4;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--accent-color);
	cursor: pointer;
	border-radius: 0.5rem;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #605e46;
	width: 7px;
}

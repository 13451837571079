
.play-footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-self: flex-end;
	align-items: center;
	grid-column-gap: 1.5em;
	justify-content: space-between;
	
	&__btn {
		cursor: pointer;
		padding: 0.8em 2em;
		min-width: 10rem;
		max-width: 100%;
		font-family: var(--ffm-main);
		color: var(--clr-purlple);
		background-color: var(--accent-color);
		font-size: 0.75rem;
		border: none;
		border-radius: 1rem;
		align-self: center;
		height: 1.7rem;
		line-height: 0;
		outline: none;
		grid-column: 2/3;
		
		&:disabled {
			/*background-color: var(--disabled-color);*/
			cursor: not-allowed;
			opacity: 0.1;
		}
		
	}
	
		&__another {
		grid-column: 3/4;
		
	}
}


.progress {
	width: 80%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-column: 1/2;
	
	&__percentage {
		font-size: 0.7rem;
		font-family: var(--ffm-main);
		color: var(--primary-color);
		padding: 0;
		margin: 0;
		line-height: 0;
	}
	
	&__container {
		background-color: #302F2F;
		width: 85%;
		border-radius: 1rem;
		height: 0.5rem;
	}
	
	&__progress-bar {
		height: 0.5rem;
		border-radius: 1rem;
		animation: progressAnimation 4s;
		transition: 4000ms ease-in-out;
		transition-property: width, background-color;
		background-color: #FF6E26;
	}
	

	
	
}


@keyframes progressAnimation {
	0% {
		width: 0;
	}
}


.timeline {
	display: grid;
	grid-template-columns: 1.2fr 1fr 1fr 1fr;
	width: 100%;
	height: 6rem;
	justify-items: center;
	justify-self: flex-end;
	grid-column: 3/4;
	
	&__text {
		font-size: 0.4rem;
		font-family: var(--ffm-main);
		color: var(--primary-color);
		margin-top: 2em;
		text-align: center;
		width: 3.4rem;
	}
	
	&__card {
		width: 100%;
		justify-self: center;
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		//justify-content: center;
		padding: 0;
		margin: 0;
		opacity: 0.6;
		transition: opacity 500ms ease-in-out;
	}
	
	&__card-visible {
		opacity: 1;
	}
}


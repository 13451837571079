.shadow {
	display: grid;
	grid-template-columns:0.6fr 10% 1fr;
	width: 100%;
	height: 100%;
	position: absolute;
	
	
	&__deck {
		grid-column: 1/2;
		justify-self: center;
		align-self: center;
	}
	
	&__container {
		position: relative;
		width: 100%;
		height: 100%;
	}
	
	&__details {
		height: 100%;
		grid-column: 3/4;
		grid-row: 1/2;
		padding-top: 2em;
		display: grid;
		grid-template-rows: 3% 0.82fr 8%;
		//max-height: 35rem;
		align-self: center;
	}
	
	&__text-main {
		display: grid;
		grid-template-columns: 1fr;
		height: 18rem;
		overflow-y: auto;
		margin-top: 2em;
		padding: 0 1em 0 0;
	}
	
	&__text-container {
		grid-column: 1/2;
		grid-row: 1/2;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 0.95rem;
		margin-bottom: 1em;
		color: var(--primary-color);
	}
	
	&__explore {
		flex-direction: column;
		//justify-content: center;
		//align-items: center;
		align-self: center;
		justify-self: center;
		grid-row: 2/3;
		width: 70%;
	}
}

.home-details {
	padding-right: 2.6em;
	display: grid;
	grid-template-columns: 1fr;
	width: 100%;
	
	.home-details__column {
		grid-row: 1/2;
		grid-column: 1/2;
	}
	
	.home-details__header {
		
		margin: 2em 0;
		font-family: var(--ffm-main);
		font-weight: 400;
		font-size: 2rem;
		color: var(--primary-color);
	}
	
	.home-details__para {
		font-family: var(--ffm-roboto);
		font-size: 0.95rem;
		color: var(--primary-color);
		margin-bottom: 1.5em;
	}
}


/*
import React, {useEffect, useState} from 'react';
import './HomeDetails.scss';
import {animated, useSpring} from "react-spring";


const HomeDetails = ({detailsActive = false, ...props}) => {


	// const fadeInTransition = useTransition(detailsActive, null, {
	// 	from: {opacity: 0},
	// 	enter: {opacity: 1},
	// 	leave: item => async (next, cancel) => {
	// 		await next({opacity: 0.75})
	// 		await next({opacity: 0})
	// 	},
	// })
	//
	// const fadeInTransition2 = useTransition(detailsActive, null, {
	// 	from: {opacity: 0},
	// 	enter: {opacity: 1},
	// 	leave: item => async (next, cancel) => {
	// 		await next({opacity: 0.75})
	// 		await next({opacity: 0})
	// 	},
	// })

	const [animationOver, setAnimationOver] = useState(false)

	console.log(detailsActive);


	useEffect(() => {
		if (detailsActive) {
			setTimeout(() => {
				setAnimationOver((value) => !value)
			}, 800)
		}
	}, [detailsActive])

	return (
		<div className="home-details">
			{

				!animationOver ?
					<Description detailsActive={detailsActive}/>
					: <Details detailsActive={detailsActive}/>
			}
		</div>
	);
}


export default HomeDetails;


const Description = ({detailsActive, ...props}) => {
	const fadeInAnimation = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
			background: "red"

		},
		config: {duration: 1000}
	});
	const fadeOutAnimation = useSpring({
		from: {
			opacity: 1,
			background: "red"
		},
		to: {
			opacity: 0,
			background: "blue"
		}, config: {duration: 3000}
	})

	const textPopUpAnimation = useSpring({
		from: {
			opacity: 0,
			transform: `translate(0, 50px)`
		},
		to: [
			{
				opacity: 1,
				transform: `translate(0, 0px)`
			}
		],
		config: {duration: 500}
	});

	return (
		<animated.div className={"home-details__column"}
					  style={!detailsActive ? fadeInAnimation : fadeOutAnimation}>
			<animated.div style={textPopUpAnimation}>
				<h1 className="home-details__header" >Welcome to Divine Play</h1>
				<p className="home-details__para">
					Use <strong>Divine Play</strong> to understand who you really are, what
					motivates you, what controls you, what attracts you, what limits you, your fears,
					your strengths and your truth. This is an
					opportunity to be more conscious, less restricted more complete, more Whole, to
					truly <strong>HEAL</strong> ” - Mynavati
				</p>
			</animated.div>
			<p className="home-details__para">
				It uses <strong>Archetypes</strong>, the original psychic imprinting on our
				Collective
				Unconscious
				and <strong>Dualities</strong> –
				interrelated opposites in our relative world, in order to understand the world
				within you.
			</p>
			<p className="home-details__para">
				Our world is a world of Duality and <strong>Divine Play</strong> shows you how to be
				creative
				and
				have
				fun with the
				energies of <strong>Dualities</strong>, so that you are not a victim of them, so
				that you
				can be
				a
				master of them and
				move you towards Healing, Wholeness and Freedom on whatever level you are ready for.
			</p>
		</animated.div>
	)

}

const Details = ({detailsActive, ...props}) => {
	const fadeInAnimation = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
			background: "red"

		},
		config: {duration: 1000}
	});
	const fadeOutAnimation = useSpring({
		from: {
			opacity: 1,
			background: "red"
		},
		to: {
			opacity: 0,
			background: "blue"
		}, config: {duration: 3000}
	})

	return (
		<animated.div className={"home-details__column"}
							  style={
								  !detailsActive ? {
										  marginTop: '1em',
										  ...fadeOutAnimation
									  } :
									  {
										  marginTop: '1em',
										  ...fadeInAnimation
									  }}>
					<p className="home-details__para">
						<strong>Divine Play</strong> represents ever-changing Maya, (Power of Illusion)
						manifested
						by
						the Creator as <strong>Divine Play</strong> – what the Vedic tradition refers to as
						God’s <strong>Leela</strong> or God’s Sport.
					</p>
					<p className="home-details__para">
						It will show you what <strong>Archetypes</strong> and <strong>Dualities</strong> you
						are
						attached to and bound by and it will
						speak to your unconscious and conscious mind. It will help you to know what is
						motivating
						you,
						what is holding you back and what within you needs to come into consciousness. It
						will
						reveal
						your past and present psyche and it will help you to make decisions as well as
						choose
						happier
						outcomes for you.
					</p>

					<p className="home-details__para">It will appear to know you as if it is intimately
						related to
						you –
						In fact, it is a reflection of
						YOU.
					</p>
					<p className="home-details__para"><strong> Divine Play</strong> has been designed for
						personal
						consultation. But, it
						can be helpful for couples to understand one another better and it is especially
						good for
						psychotherapists and their clients in helping to gain more insight into the
						subconscious and
						unconscious levels motivating the individual.
					</p>

					<p className="home-details__para">All that is required to participate, is openness,
						honesty and
						courage, to allow the continual
						process of life and its changes, its re-routing, its beginnings and endings…your
						eternal
						journey
						to unfold.</p>
				</animated.div>
	)

}


 */

.mobile {
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2em 2em 4em;
	overflow-x: hidden;
	
	&__title {
		margin: 0 auto;
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.6rem;
		padding: 0;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		margin: 1em 0;
		color: var(--primary-color);
		text-align: center;
	}
	
	
	&__animation {
		display: grid;
		grid-template-columns: repeat(2, 9rem);
		grid-template-rows: repeat(2, 9rem);
		grid-gap: 0.8em;
		margin: 2em 0;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	
	&__element-card {
		object-fit: cover;
		width: 100%;
		height: 100%;
		will-change: transform;
	}
	
	&__collapsing__card {
		position: absolute;
		width: 5rem;
		height: 8rem;
		box-shadow: var(--bs-primary);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding: 0;
		margin: 0;
		will-change: top, left;
	}
}


@media (max-width: 770px) {
	.mobile {
		display: flex;
	}
}

@media (min-width: 771px) {
	.mobile {
		display: none;
	}
}








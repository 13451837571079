.duality {
	display: grid;
	grid-template-columns:0.6fr 10% 1fr;
	width: 100%;
	height: 100%;
	
	
	&__deck {
		grid-column: 1/2;
		justify-self: flex-end;
		align-self: center;
	}
	
	&__container {
		position: relative;
		width: 100%;
		height: 100%;
	}
	
	
	&__details {
		height: 100%;
		max-height: 40rem;
		grid-column: 3/4;
		padding-top: 3em;
		display: grid;
		grid-template-rows: 5% 75% 10%;
		align-self: center;
	}
	
	&__text-main {
		position: relative;
	}
	
	&__text-container {
		padding: 2em 0 0 0;
		position: absolute;
		z-index: -1;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 1.1rem;
		margin-bottom: 2em;
		color: var(--primary-color);
	}
	
}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.duality {
		&__details {
			max-height: 30rem;
			grid-column: 3/4;
			padding-top: 3em;
			display: grid;
			grid-template-rows: 5% 75% 10%;
		}
		
		
	}
}

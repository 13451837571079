.chakra {
	display: grid;
	grid-template-columns:0.6fr 10% 1fr;
	width: 100%;
	height: 100%;
	
	
	&__details {
		grid-column: 1/2;
		justify-self: flex-end;
		align-self: center;
		width: 50%;
		display: flex;
		justify-content: flex-end;
	}
	
	&__description {
		grid-column: 3/4;
		align-self: center;
	}
	
	
	&__element-details {
		grid-column: 1/2;
		align-self: center;
		justify-self: flex-end;
		position: relative;
	}
	
	
	&__element-card {
		width: 21rem;
		height: 21rem;
		border-radius: 3em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	&-flip__container {
		position: absolute;
		top: 10%;
		left: -4rem;
		display: grid;
		grid-template-columns: 10rem;
		grid-template-rows: 17rem;
		width: 100%;
		height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 5rem 8.5rem;
		will-change: transform;
		perspective: 600px;
		z-index: 1000;
	}
	
	&-flip__container-2 {
		align-self: center;
		display: grid;
		grid-template-columns: 12rem;
		grid-template-rows: 20rem;
		width: 100%;
		height: 100%;
		cursor: pointer;
		transform-style: preserve-3d;
		transform-origin: 6rem 10rem;
		will-change: transform;
		perspective: 600px;
		z-index: 1000;
	}
	
	&-flip__card {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		grid-column: 1/2;
		grid-row: 1/2;
		border-radius: 1em;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	&-element__description {
		grid-column: 3/4;
		height: 70%;
		margin-top: 1em;
		align-self: center;
		display: grid;
		grid-template-columns: 1fr;
		//grid-template-rows: 1fr;
		max-height: 30rem;
	}
	
	&__btn {
		cursor: pointer;
		padding: 0.8em 0;
		font-family: var(--ffm-main);
		color: var(--clr-purlple);
		background-color: var(--accent-color);
		font-size: 0.75rem;
		border: none;
		border-radius: 1rem;
		margin: 0 1em 0 0;
		height: 1.7rem;
		line-height: 0;
		outline: none;
	}
	
	
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.5rem;
		width: 80%;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 0.85rem;
		margin-bottom: 1em;
		color: var(--primary-color);
	}
	
	&__sub-text {
		font-family: var(--ffm-main);
		font-size: 0.85rem;
		color: var(--primary-color);
		line-height: 0;
	}
	
	&__btn-container {
		display: flex;
		align-items: center;
		margin-bottom: 1.5em;
	}
	
	&__nav-container {
		display: flex;
		justify-content: space-between;
		width: 50%;
		margin: 0 0 1rem 0;
	}
	
	&__nav-slide {
		width: 30px;
		height: 4px;
		background-color: var(--primary-color);
		position: fixed;
		transition: top ease-in-out 800ms, left ease-in-out 800ms;
	}
	
	&__prescription {
		font-family: var(--ffm-roboto);
		font-size: 0.85rem;
		color: var(--primary-color);
		font-style: italic;
	}
	
	&__input {
		line-height: 4ch;
		background-image: linear-gradient(transparent, transparent calc(4ch - 1px), #FFFFFF 0px);
		background-size: 50% 4ch;
		border: none;
		background-color: transparent;
		outline: none;
		width: 100%;
		height: 100%;
		max-height: 17rem;
		
		resize: none;
		font-family: var(--ffm-roboto);
		color: var(--primary-color);
		font-size: 0.9rem;
		overflow: hidden;
		will-change: width;
		
		&::placeholder {
			font-family: var(--ffm-roboto);
			color: var(--primary-color);
			font-style: italic;
			font-size: 1rem;
		}
		
		
		&-counter {
			position: absolute;
			top: 90%;
			right: 0;
			padding: 0;
			margin: 0;
			font-weight: 400;
			font-family: var(--ffm-roboto);
			font-size: 1rem;
			color: var(--primary-color);
		}
		
		&-container {
			height: 50%;
			position: relative;
		}
	}
	
	
	&__nav {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		height: 13rem;
		width: 100%;
		overflow-y: auto;
	}
	
	&__nav-content {
		grid-column: 1/2;
		grid-row: 1/2;
		will-change: opacity;
		height: 100%;
		padding-right: 1em;
	}
	
}

@media only screen and (min-width: 772px) and (max-width: 1150px) {
	.chakra {
		display: grid;
		grid-template-columns:0.7fr 5% 1fr;
		width: 100%;
		height: 100%;
		
		
		&__overflow {
			height: 25rem;
			overflow-y: auto;
		}
		
		&__element-card {
			width: 18rem;
			height: 18rem;
			border-radius: 2em;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		
		&-flip__container {
			top: 10%;
			left: -4rem;
			grid-template-columns: 8rem;
			grid-template-rows: 14rem;
			transform-origin: 4rem 7rem;
			
		}
	}
}

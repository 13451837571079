.pos {
	&__title {
		color: var(--primary-color);
		font-family: var(--ffm-main);
		font-size: 1.2rem;
		margin: 1.5em 0;
	}
	
	&__description {
		font-family: var(--ffm-roboto);
		font-size: 1rem;
		margin-bottom: 1em;
		color: var(--primary-color);
	}
}
